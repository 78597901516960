@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  input[type="number"] {
    -moz-appearance: textfield;
  }

  /* Webkit browsers like Safari and Chrome */

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  html,
  body {
    min-height: 100vh;
    height: -webkit-fill-available;
    background-color: hsl(var(--background));
    color: hsl(var(--text));
    margin: 0;
    @apply subpixel-antialiased;
    font-family: var(--font-family);
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: var(--font-family-title);
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }

  /* For Webkit-based browsers (Chrome, Safari and Opera) */
  .scrollbar-hide::-webkit-scrollbar {
    display: none;
  }

  /* For IE, Edge and Firefox */
  .scrollbar-hide {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .full {
    flex: 1;
  }

  #content {
    @apply flex-auto flex h-full flex-col;
  }

  div[data-rsbs-backdrop] {
    @apply z-50;
  }

  div[data-rsbs-header]::before {
    @apply bg-muted-foreground/50;
  }

  div[data-rsbs-overlay] {
    @apply z-50 max-w-2xl mx-auto bg-card;
  }

  #root {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
  }

  .strikethrough {
    position: relative;
  }
  .strikethrough:before {
    position: absolute;
    content: "";
    left: 0;
    top: 50%;
    right: 0;
    border-top: 1px solid;
    border-color: inherit;

    -webkit-transform: rotate(-5deg);
    -moz-transform: rotate(-5deg);
    -ms-transform: rotate(-5deg);
    -o-transform: rotate(-5deg);
    transform: rotate(-5deg);
  }

  @media not print {
    .print-surface {
      @apply flex flex-row flex-wrap gap-5 p-10 items-center justify-center place-content-center pb-32 bg-white;
    }

    .print-item {
      @apply shadow-md ring-1 ring-zinc-300;
    }

    .rounded-qr-print {
      @apply rounded-full;
    }
  }

  @media print {
    html,
    body {
      height: initial !important;
      overflow: initial !important;
      --webkit-print-color-adjust: exact;
      @apply bg-white m-0 p-0;
    }

    @page {
      overflow: hidden;
    }

    .print-item {
      page-break-after: always;
      page-break-before: always;
      break-after: always;
      break-before: always;
      break-inside: avoid;
      page-break-inside: avoid;
      margin: 0;
      padding: 0;
      border: 0;
      overflow: hidden;
    }
  }

  .price-line-through {
    background-color: transparent;
    background-image: gradient(
      linear,
      19.1% -7.9%,
      81% 107.9%,
      color-stop(0, transparent),
      color-stop(0.48, transparent),
      color-stop(0.5, gray),
      color-stop(0.52, transparent),
      color-stop(1, transparent)
    );
    background-image: repeating-linear-gradient(
      163deg,
      transparent 0%,
      transparent 48%,
      gray 50%,
      transparent 52%,
      transparent 100%
    );
  }

  .place-cover-img {
    filter: blur(8px);
    mask-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 1),
      rgba(0, 0, 0, 0.3)
    );
    position: absolute;
    top: 0;
    min-height: 100%;
    min-width: 100%;
    background-size: cover;
    background-position: center;
    transform: scale(1.3);
    opacity: 0.6;
  }

  .safe-area-bottom {
    padding-bottom: env(safe-area-inset-bottom, 0);
  }
  .safe-area-bottom-5 {
    padding-bottom: env(safe-area-inset-bottom, 1.25rem);
  }

  .cover-img-wraper {
    position: absolute;
    overflow: hidden;
    right: 0;
    bottom: 0;
    left: 0;
    min-height: 100%;
    filter: blur(4px);
    mask-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 1),
      rgba(0, 0, 0, 0.3)
    );
  }

  .cover {
    flex-basis: 30rem;
    justify-content: flex-end;
    position: relative;
    display: flex;
    flex: 0 0;
    flex-direction: column;
    background: var(--background);
    margin-top: -85px;
  }

  .cover-img {
    position: absolute;
    top: 0;
    min-height: 100%;
    min-width: 100%;
    background-size: cover;
    background-position: center;
  }

  .center-divider {
    position: relative;
    text-align: center;
  }

  .center-divider label {
    background-color: hsl(var(--card));
    padding: 0 0.4em;
    position: relative;
  }

  .center-divider:before {
    content: "";
    border-style: solid;
    border-width: 0 0 1px 0;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    border-color: hsl(var(--muted-foreground));
  }

  .lighter-bg-1 {
    background: radial-gradient(
      circle at 50% -20%,
      #f57835,
      #ff7892,
      #e797d5,
      #b9b7ec,
      #b0cae1
    );
  }

  .react-datepicker__input-container input {
    @apply block w-full text-base md:text-sm bg-white border border-gray-300 rounded shadow-sm form-input;
  }

  .react-datepicker-popper {
    @apply z-40 w-72 text-sm bg-card rounded-md shadow px-3 py-2 mt-1;
  }

  .react-datepicker-left {
    @apply absolute left-0 right-auto top-11 transform-none !important;
  }

  .react-datepicker-right {
    @apply absolute right-0 left-auto top-11 transform-none !important;
  }

  .react-datepicker__portal {
    @apply absolute z-10 w-72 text-sm transform-none bg-card shadow px-3 py-2 top-12 right-0 border-2 border-gray-500 border-opacity-30 rounded;
  }

  .react-datepicker__month-container {
    @apply flex flex-col;
  }

  .react-datepicker__month {
    @apply flex flex-col;
  }

  .react-datepicker__current-month {
    @apply ml-2.5 text-lg font-semibold text-gray-800 dark:text-gray-200;
  }

  .react-datepicker__week {
    @apply flex justify-around;
  }

  .react-datepicker__aria-live {
    @apply hidden;
  }

  .react-datepicker__day-names {
    @apply flex justify-around text-gray-400 font-medium text-center text-xs;
  }

  .react-datepicker__day-name {
    @apply w-8 h-8 flex items-center justify-center py-1 rounded-full;
  }

  .react-datepicker__navigation {
    @apply absolute top-2;
  }

  .react-datepicker__navigation--previous {
    @apply right-12 w-8 h-8 rounded transition flex items-center justify-center hover:bg-gray-200;
  }

  .react-datepicker__navigation--next {
    @apply right-4 w-8 h-8 rounded transition flex items-center justify-center hover:bg-gray-200;
  }

  .react-datepicker__day {
    @apply mb-1 w-8 h-8 flex items-center justify-center py-1 text-sm leading-loose transition text-gray-700 rounded cursor-pointer;
  }

  .react-datepicker__day--disabled {
    @apply cursor-not-allowed opacity-40 hover:bg-transparent;
  }

  .react-datepicker__day--outside-month {
    @apply text-gray-300;
  }

  .react-datepicker__day--in-range {
    @apply bg-gray-200;
  }

  .react-datepicker__day--in-selecting-range {
    @apply bg-blue-200;
  }

  .react-datepicker__day--selecting-range-start {
    @apply bg-background border-2 border-primary;
  }

  .react-datepicker__day--selecting-range-end {
    @apply bg-background border-2 border-primary;
  }

  .react-datepicker__day--selected {
    @apply bg-primary text-background;
  }

  .react-datepicker__day--range-start {
    @apply bg-primary text-background hover:bg-background; /** hover:text-text-700 **/
  }

  .react-datepicker__day--range-end {
    @apply bg-primary text-background hover:bg-background; /** hover:text-text-700 **/
  }

  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
    font-feature-settings: "rlig" 1, "calt" 1;
  }
}
